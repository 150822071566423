import React from 'react';
// You need to install React/React-DOM
import { Fireworks } from 'fireworks/lib/react'

function firewwork() {
  let fxProps = {
    count: 2,
    interval: 400,
    colors: ['#B8860B', '#F4D03F', '#F9C904','#FFD700','#DAA520'],
    calc: (props: any, i: number) => ({
      ...props,
      x: (i + 1) * (window.innerWidth / 2) - (i + 1) * 100,
      y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
    })
  }

  return (
    <div>
      <Fireworks {...fxProps} />
      
    </div>
  )
}
export default firewwork;